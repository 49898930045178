<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 px-6">
            <div class="row align-items-center"></div>
            <div class="card-toolbar">
              <b-button
                variant="success"
                v-b-modal.modal-category-edit
                @click="setNewCategory()"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                </span>
                {{ $t("mealsystem.categories.button.create") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--begin::Card-->
      <div
        class="col-md-4 gutter-b"
        v-for="(category, index) in categories.data"
        :key="index"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h5 class="card-label">
                {{ category.name }}
              </h5>
            </div>
            <div class="card-toolbar">
              <a
                class="
                  btn
                  btn-link
                  btn-sm
                  btn
                  btn-clean
                  btn-hover-light-danger
                  btn-sm
                  btn-icon
                "
                v-b-tooltip.top="
                  $t('mealsystem.categories.title.delete_category')
                "
                v-b-modal.modal-category-delete
                @click="setCategory(category)"
                ><i class="ki ki-bold-close icon-sm text-danger"></i
              ></a>
              <a
                class="
                  btn
                  btn-link
                  btn-sm
                  btn
                  btn-clean
                  btn-hover-light-primary
                  btn-sm
                  btn-icon
                "
                v-b-tooltip.top="$t('button.settings')"
                v-b-modal.modal-category-edit
                @click="loadCategory(category)"
                ><i class="ki ki-gear icon-md"></i
              ></a>
            </div>
          </div>
          <div class="card-body pt-5">
            <span class="text-muted mt-3 font-weight-bold font-size-sm"
              >{{ $t("mealsystem.categories.label.parameters") }}:</span
            >
            <div class="d-flex align-items-center mt-3">
              <span
                class="bullet bullet-bar bg-default align-self-stretch"
              ></span>
              <div class="d-flex flex-column flex-grow-1 ml-5">
                <span class="text-dark-75">{{
                  $t("mealsystem.categories.label.coeficient")
                }}</span>
              </div>
              <strong>{{ category.portion_coefficient }}</strong>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span
                class="bullet bullet-bar bg-default align-self-stretch"
              ></span>
              <div class="d-flex flex-column flex-grow-1 ml-5">
                <span class="text-dark-75">{{
                  $t("mealsystem.categories.label.category_type")
                }}</span>
              </div>
              <b-badge variant="success">{{ category.type }}</b-badge>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span
                class="bullet bullet-bar bg-default align-self-stretch"
              ></span>
              <div class="d-flex flex-column flex-grow-1 ml-5">
                <span class="text-dark-75">{{
                  $t("mealsystem.categories.label.age_range")
                }}</span>
              </div>
              <strong>{{ category.age_from }} - {{ category.age_to }}</strong>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span
                class="bullet bullet-bar bg-default align-self-stretch"
              ></span>
              <div class="d-flex flex-column flex-grow-1 ml-5">
                <span class="text-dark-75">{{
                  $t("mealsystem.categories.label.account_to_minus")
                }}</span>
              </div>
              <strong
                class="text-success"
                v-if="category.account_to_minus == 1"
                >{{ $t("mealsystem.title.yes") }}</strong
              >
              <strong class="text-danger" v-else>{{
                $t("mealsystem.title.no")
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-category-delete"
      :okTitle="$t('button.delete')"
      okVariant="danger"
      :title="$t('mealsystem.categories.title.confirm_delete_category_title')"
    >
      <span
        v-html="$t('mealsystem.categories.title.confirm_delete_category_text')"
      ></span>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteCategory(selectedCategory)">
          {{ $t("mealsystem.categories.button.delete_category") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-category-edit"
      size="lg"
      :okTitle="$t('button.save')"
      okVariant="success"
      :title="$t('mealsystem.categories.title.edit_category')"
    >
      <ValidationObserver ref="form">
        <div class="d-flex">
          <div class="col-6">
            <span class="mb-2 d-block">
              <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
                {{ $t("mealsystem.categories.label.name") }}
              </h3>
            </span>
            <div>
              <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('mealsystem.categories.placeholder.name')"
                  v-model="selectedCategory.name"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-5 offset-1">
            <span class="mb-2 d-block">
              <h3 class="font-size-lg text-primary font-weight-bolder mb-3">
                <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                  <inline-svg src="/media/svg/icons/Code/Settings4.svg" />
                </span>
                {{ $t("mealsystem.categories.label.coeficient") }}
              </h3>
            </span>
            <div>
              <ValidationProvider rules="required|min:1" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="
                    $t('mealsystem.categories.placeholder.coeficient')
                  "
                  v-model="selectedCategory.portion_coefficient"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <hr />

        <b-tabs card class="mt-4" active-nav-item-class="bg-primary text-white">
          <b-tab :title="$t('mealsystem.categories.title.tab_main_info')">
            <b-card-text>
              <div class="d-flex align-items-top">
                <div class="col-6 pr-7">
                  <strong class="d-block mb-2">{{
                    $t("mealsystem.categories.label.age_range")
                  }}</strong>
                  <div class="d-flex mb-8">
                    <input
                      type="text"
                      class="form-control col-5"
                      :placeholder="
                        $t('mealsystem.categories.placeholder.age_from')
                      "
                      v-model="selectedCategory.age_from"
                    />
                    <div
                      class="
                        col-2
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      -
                    </div>
                    <input
                      type="text"
                      class="form-control col-5"
                      :placeholder="
                        $t('mealsystem.categories.placeholder.age_to')
                      "
                      v-model="selectedCategory.age_to"
                    />
                  </div>

                  <strong class="d-block mb-2">{{
                    $t("mealsystem.categories.label.allow_to_minus")
                  }}</strong>
                  <b-form-checkbox
                    v-model="selectedCategory.account_to_minus"
                    name="account_to_minus"
                    switch
                    size="l"
                    class="d-inline"
                    value="1"
                    unchecked-value="0"
                    >{{
                      $t("mealsystem.categories.label.allow_to_minus")
                    }}</b-form-checkbox
                  >
                </div>
                <div class="col-6 flex-column border-left d-flex pl-7">
                  <div class="mb-8">
                    <strong class="d-block mb-2">{{
                      $t("mealsystem.categories.label.type")
                    }}</strong>
                    <vs-select
                      v-model="selectedCategory.type"
                      :options="types_options"
                      :placeholder="
                        $t('mealsystem.categories.placeholder.categories')
                      "
                      :clearable="false"
                    />
                  </div>

                  <strong class="d-block mb-2">{{
                    $t("mealsystem.categories.label.allowed_orders")
                  }}</strong>
                  <b-form-checkbox
                    v-model="selectedCategory.allowed_from_internet"
                    name="allowed_from_internet"
                    switch
                    size="l"
                    class="d-inline"
                    value="1"
                    unchecked-value="0"
                    >{{
                      $t("mealsystem.categories.label.allowed_from_internet")
                    }}</b-form-checkbox
                  >

                  <b-form-checkbox
                    v-model="selectedCategory.allowed_from_terminal"
                    name="allowed_from_terminal"
                    switch
                    size="l"
                    class="d-inline mt-2"
                    value="1"
                    unchecked-value="0"
                    >{{
                      $t("mealsystem.categories.label.allowed_from_terminal")
                    }}</b-form-checkbox
                  >
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab :title="$t('mealsystem.categories.title.tab_food_types')">
            <b-card-text>
              <div class="row">
                <div class="col-3 form-group">
                  <label
                    class="col-form-label text-success font-weight-bolder"
                    >{{
                      $t("mealsystem.categories.title.add_food_type")
                    }}</label
                  >
                </div>
                <div class="col-6">
                  <vs-select
                    v-if="foodtypes"
                    :options="foodtypes"
                    :placeholder="$t('mealsystem.placeholder.search_food_type')"
                    label="name"
                    :reduce="(foodtypes) => foodtypes.food_id"
                    v-model="newFoodTypeSelect"
                  />
                </div>
                <div class="col-3">
                  <b-button variant="success" @click="addNewFoodType()">
                    <span class="svg-icon svg-icon-md svg-icon-white">
                      <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                    </span>
                    {{ $t("mealsystem.categories.button.create_food_type") }}
                  </b-button>
                </div>
              </div>

              <table
                class="table"
                v-if="
                  selectedCategory.food_types &&
                  selectedCategory.food_types.length > 0
                "
              >
                <thead>
                  <tr>
                    <th width="120">
                      {{ $t("mealsystem.categories.label.foodname") }}
                    </th>
                    <th>{{ $t("mealsystem.categories.label.buy_price") }}</th>
                    <th>{{ $t("mealsystem.categories.label.sell_price") }}</th>
                    <th width="90"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(foodtype, index) in selectedCategory.food_types" :key="index">
                    <td class="align-middle font-weight-bolder">
                      {{ foodtype.name }}
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        v-model="foodtype.pivot.purchase_price"
                        :placeholder="
                          $t('mealsystem.categories.placeholder.buy_price')
                        "
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        v-model="foodtype.pivot.selling_price"
                        :placeholder="
                          $t('mealsystem.categories.placeholder.sell_price')
                        "
                      />
                    </td>
                    <td>
                    <a
                      class="
                        btn
                        btn-link
                        btn-sm
                        btn
                        btn-clean
                        btn-hover-light-danger
                        btn-sm
                        btn-icon
                      "
                      @click="deleteFoodType(foodtype)"
                      ><i class="ki ki-bold-close icon-sm text-danger"></i
                    ></a>
                    <a
                      class="
                        btn
                        btn-link
                        btn-sm
                        btn
                        btn-clean
                        btn-hover-light-success
                        btn-sm
                        btn-icon
                      "
                      @click="updateFoodType(foodtype)"
                      ><i class="far fa-save text-success"></i
                    ></a>
                  </td>
                  </tr>
                </tbody>
              </table>
              <div class="py-20 text-muted text-center" v-else>
                {{ $t("mealsystem.categories.title.no_foodtype_in_category") }}
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()" class="mr-3">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button variant="success" @click="updateCategory(selectedCategory)">
          {{ $t("mealsystem.categories.button.save_category") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      categories: null,
      loading: false,
      selectedCategory: {},
      types_options: [
        "zakladni.skola",
        "materska.skola",
        "stredni.skola",
        "cizi.stravnik",
      ],
      foodtypes: null,
      newFoodTypeSelect: null
    };
  },
  components: {},
  beforeMount() {
    this.getCategories();
    this.getFoodTypes();
  },
  metaInfo() {
    return { title: this.$t("mealsystem.meta.diners_categories") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.diners"),
        subtitle: this.$t("mealsystem.meta.diners_categories"),
      },
    ]);
  },
  methods: {
    getCategories() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/diners/categories").then((response) => {
        this.categories = response.data;
        this.loading = false;
      });
    },
    setCategory(category) {
      this.selectedCategory = category;
    },
    setNewCategory() {
      this.selectedCategory = {
        age_from: 14,
        age_to: 18,
        portion_coefficient: 1,
        type: "zs",
      };
    },
    loadCategory(category) {
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners/categories/" + category.diners_category_id
      ).then((response) => {
        this.selectedCategory = response.data.data;
      });
    },
    deleteCategory(category) {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/diners/categories/" +
          category.diners_category_id +
          "/delete"
      )
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("modal-category-delete");
          this.getCategories();
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    updateCategory(category) {
      this.loading = true;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        }

        if (category.diners_category_id) {
          this.endpoint =
            "/meal-system/diners/categories/" +
            category.diners_category_id +
            "/update";
        } else {
          this.endpoint = "/meal-system/diners/categories";
        }

        ApiService.setHeader();
        ApiService.apiPost(this.endpoint, {
          name: category.name,
          portion_coefficient: category.portion_coefficient,
          age_from: category.age_from,
          age_to: category.age_to,
          account_to_minus: category.account_to_minus,
          type: category.type,
        })
          .then(() => {
            this.loading = false;
            this.$bvModal.hide("modal-category-edit");
            this.getCategories();
          })
          .catch((error) => {
            checkErrors(error);
          });
      });
    },
    addNewFoodType() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/diners/categories/" +
          this.selectedCategory.diners_category_id +
          "/food-types",
        {
          food_type_id: this.newFoodTypeSelect,
          purchase_price: 1,
          selling_price: 1,
        }
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/diners/categories/" + this.selectedCategory.diners_category_id
          ).then((response) => {
            this.selectedCategory = response.data.data;
            this.newFoodTypeSelect = null;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    deleteFoodType(foodtype){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/diners/categories/food-types/" +
          foodtype.pivot.diner_category_food_type_id +
          "/delete"
      )
        .then(() => {
          this.loading = false;
          ApiService.setHeader();
          ApiService.apiGet(
            "/meal-system/diners/categories/" + foodtype.pivot.diners_category_id
          ).then((response) => {
            this.selectedCategory = response.data.data;
          });
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    updateFoodType(foodtype){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiPost(
        "/meal-system/diners/categories/food-types/" +
          foodtype.pivot.diner_category_food_type_id +
          "/update",
        {
          food_type_id: foodtype.pivot.food_type_id,
          purchase_price: foodtype.pivot.purchase_price,
          selling_price: foodtype.pivot.selling_price,
        }
      )
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          checkErrors(error);
        });
    },
    getFoodTypes() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/meal-system/food-types").then((response) => {
        this.foodtypes = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
